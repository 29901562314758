






































    import {Component, Vue} from "vue-property-decorator";
    import MasrafCesitleriForm from "@/components/tercihler/MasrafCesitleriForm.vue";
    import {FaizListResponseProvider} from "@/services/FaizService";
    import FaizlerForm from "@/components/tercihler/FaizlerForm.vue";
    import OranList from "@/components/tercihler/OranList.vue";
    import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
    import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
    import {FaizPeriyotLabel} from "@/enum/FaizPeriyot";
import { FaizEntity } from '@/entity/FaizEntity';

    @Component({
        components: {FormDialogButton, OranList, DeleteButton, FaizlerForm, MasrafCesitleriForm}
    })
    export default class FaizListesi extends Vue {
        data: Array<FaizEntity> = [];
        items: Array<object> = [];
        dialog: boolean = false;
        oranDialog: boolean = false;
        editingObject!: any;
        search: string = "";
        FaizPeriyotLabel=FaizPeriyotLabel;
        headers = [
            {
                text: "Faiz Adı",
                align: "start",
                sortable: false,
                value: "ad"
            },
            {
                text: "Periyot",
                align: "start",
                sortable: false,
                value: "periyot"
            },
            {
                text: "Oran",
                value: "oran"
            },
            {
                text: "İşlemler",
                value: "actions"
            }

        ];

        yeniFaizObj = {standartMi: false, ad: '', oran: '', periyot: null};

        faizEkle() {
            this.editingObject = Object.assign({standartMi: false, ad: '', oran: '', periyot: null});
            console.log("basıldı", this.editingObject);
        }

        detay(val: any) {
            this.editingObject = val;
        }

        oranDetay(val: any) {
            this.editingObject = val;
            console.log("mother?:", this.editingObject);
        }

        mounted() {
            this.data = FaizListResponseProvider().data;
            let items: Array<object> = [];
            let currentDate = new Date();
            this.data.forEach((faiz: FaizEntity) => {
                let item: any = {};
                item.id = faiz.id;
                item.ad = faiz.ad;
                item.periyot = faiz.periyot;
                item.oran = faiz.faizGecmis.find(gfaiz => (gfaiz.baslangicTarihi ? gfaiz.baslangicTarihi < currentDate : false) && (gfaiz.bitisTarihi ? gfaiz.bitisTarihi > currentDate : false))?.oran;
                item.faizGecmis = faiz.faizGecmis;
                item.standartMi = faiz.standartMi;
                items.push(item);
            });
            this.items = items;
            console.log("faizler: ", this.items);
        }
    }
