<template>
    <v-card>
        <v-toolbar dense flat color="primary" dark>
            <v-toolbar-title>GENEL AYARLAR</v-toolbar-title>
        </v-toolbar>
        <v-tabs vertical>
            <v-tab>
                <v-icon left>mdi-account</v-icon>
                Sistem Ayarları
            </v-tab>
            <v-tab>
                <v-icon left>mdi-lock</v-icon>
                Zarf Ayarları
            </v-tab>
            <v-tab>
                <v-icon left>mdi-access-point</v-icon>
                Dosya Yolu/Editörler
            </v-tab>

            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                         <v-checkbox label="Yazıcı Sürekli Mi"></v-checkbox>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <v-checkbox label="Zarfta Borçlu İsmi Sorulsun"></v-checkbox>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <p>
                            Dosya Yolu: C:\icraPSQL\KayıtlıDosyalar <v-btn class="primary">Seç</v-btn>
                        </p>


                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs>
    </v-card>
</template>

<script>
    export default {
        name: "Tercihler"
    }
</script>

<style scoped>

</style>