







import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import IptalButton from "@/components/inputs/IptalButton.vue";
import KaydetButton from "@/components/inputs/KaydetButton.vue";
import {kartDurumlariEntity} from "@/entity/KartDurumlari";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {mixins} from "vue-class-component";@Component({
    components: {
        KaydetButton,
        IptalButton
    },
})
export default class KartDurumlari extends Mixins(ObjectInputMixin) {

};
