















































import {Component, Vue} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import MasrafCesidiPicker from "@/components/pickers/MasrafCesidiPicker.vue";
import MasrafTuruPicker from "@/components/pickers/MasrafTuruPicker.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import VekaletUcretOranForm from "@/components/tercihler/VekaletUcretOranForm.vue";
import {HarcGenelResponseProvider} from "@/services/HarcGenelService";
import {HarcGenelEntity} from "@/entity/HarcGenelEntity";
import HarcGenelForm from "@/components/tercihler/HarcGenelForm.vue";

@Component({
    components: {
        HarcGenelForm,
        VekaletUcretOranForm,
        DeleteButton, FormDialogButton, ItemArrayManager, MasrafTuruPicker, MasrafCesidiPicker, Dates
    }
})
export default class Harclar extends Vue {
    harclar = HarcGenelResponseProvider().data;
    selected: HarcGenelEntity | null = null;
    index: number = 0;

    mounted() {
        this.index = this.harclar.length - 1;
        this.selected = this.harclar[this.index];
        console.log("wut:", this.selected);

    }

    YilSec(param: number) {
        this.selected = this.harclar[param];
    }

}
