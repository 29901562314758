import {TakipAltTipAyar} from "@/entity/TakipAltTipAyar";
import {VekaletUcretOranEntity} from "@/entity/VekaletUcretOranEntity";
import {KisiKurumEntity} from "@/entity/KisiKurumEntity";
import {TakipTipiEntity} from "@/entity/TakipTipiEntity";
import {FaizEntity} from "@/entity/FaizEntity";
import {HesapSekli} from "@/enum/HesapSekli";
import {AlacakKalemiAlani} from "@/entity/AlacakKalemiAlani";

export class TakipAltTipiEntity {
    id: number | null = null;
    takipTipi: TakipTipiEntity | null = null;
    deger: string | null = null;
    takipAyarlari: Array<TakipAltTipAyar> | null = null;
    tahsilatDusmeSirasi: Array<AlacakKalemiAlani> | null = null;
    takipOncesiFaiz: FaizEntity | null = null;
    takipOncesiHesapSekli: HesapSekli=HesapSekli.aylik;
    takipSonrasiFaiz: FaizEntity | null = null;
    takipSonrasiHesapSekli: HesapSekli=HesapSekli.aylik;
    vekaletUcretleri: Array<VekaletUcretOranEntity> | null= null;
    alacakli: KisiKurumEntity | null = null;
    TTMetni: string | null= null;
    OEMetni: string | null= null;
    FaizAciklamaMetni: string | null= null;

    constructor(defaults:object|null = null) {
        Object.assign(this,{
            id:null,
            takipTipiId:null,
            deger:null,
            takipAyarlari: null,
            vekaletUcretileri:null,
            alacakli:null,
        },defaults ? defaults : {})
    }
}
