


















import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import IptalButton from "@/components/inputs/IptalButton.vue";
import {MasrafCesitEntity} from "@/entity/MasrafCesitEntity";
import KaydetButton from "@/components/inputs/KaydetButton.vue";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
    components: {Dates}
})
export default class MasrafForm extends Mixins(ObjectInputMixin) {
}
