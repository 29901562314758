













































import {Component, Prop, Vue} from "vue-property-decorator";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import KartDurumuForm from "@/components/tercihler/KartDurumuForm.vue";
import {kartDurumlariEntity} from "@/entity/KartDurumlari";
import {KartDurumlariListResponseProvider} from "@/services/KartDurumlariService";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";

@Component({
    components: {FormDialogButton, KartDurumuForm, DeleteButton}
})
export default class KartDurumlariList extends Vue {

    @Prop({})
    editable!: boolean;

    items = KartDurumlariListResponseProvider().data;
    headers = [
        {
            text: "Değer",
            value: "durumAdi"
        },
        {
            text: "İşlemler",
            value: "actions",
            align: "end",
        },
    ]
}
