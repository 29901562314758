import {HarcGenelEntity} from "@/entity/HarcGenelEntity";

export interface HarcGenelResponse {
    data: Array<HarcGenelEntity>
}

export function HarcGenelResponseProvider(depth: number = 1): HarcGenelResponse {
    return {
        data: [
            {
                id: 1,
                baslamaTarihi: new Date('2016-01-01'),
                hacizdenOnce: 2.60,
                hacizdenSonraSatistanOnce: 5.20,
                satistanSonra: 6.00,
                maasHaczindenSonra: 2.60,
                iflasKanunundan: 0.80,
                gayrimenkulIcraEmri: 0.30,
                gayrimenkulIcraMarifeti: 0.80,
                degisikMenkulIcraEmri: 0.80,
                degisikMenkulMarifeti: 2.60,
                icraYerineGetirmeHarci: 35.40,
                basvuruHarci: 40.40,
                vekaletHarci: 4.40,
                pesinHarcOrani: 0.50,
                cezaeviHarcOrani: 1.00,
                tebligatGideri: 13.00,
                dosyaGider: 0.75,
                evrakGideri: 0.10,
            },
            {
                id: 2,
                baslamaTarihi: new Date('2017-01-01'),
                hacizdenOnce: 3.60,
                hacizdenSonraSatistanOnce: 7.20,
                satistanSonra: 9.00,
                maasHaczindenSonra: 3.60,
                iflasKanunundan: 1.80,
                gayrimenkulIcraEmri: 0.90,
                gayrimenkulIcraMarifeti: 1.80,
                degisikMenkulIcraEmri: 1.80,
                degisikMenkulMarifeti: 3.60,
                icraYerineGetirmeHarci: 44.40,
                basvuruHarci: 44.40,
                vekaletHarci: 6.40,
                pesinHarcOrani: 0.50,
                cezaeviHarcOrani: 2.00,
                tebligatGideri: 14.00,
                dosyaGider: 1.75,
                evrakGideri: 0.50,
            },
            {
                id: 3,
                baslamaTarihi: new Date('2018-01-01'),
                hacizdenOnce: 4.60,
                hacizdenSonraSatistanOnce: 7.20,
                satistanSonra: 8.00,
                maasHaczindenSonra: 4.60,
                iflasKanunundan: 2.80,
                gayrimenkulIcraEmri: 2.30,
                gayrimenkulIcraMarifeti: 2.80,
                degisikMenkulIcraEmri: 2.80,
                degisikMenkulMarifeti: 4.60,
                icraYerineGetirmeHarci: 37.40,
                basvuruHarci: 42.40,
                vekaletHarci: 6.40,
                pesinHarcOrani: 2.50,
                cezaeviHarcOrani: 3.00,
                tebligatGideri: 15.00,
                dosyaGider: 2.75,
                evrakGideri: 2.10,
            },
            {
                id: 4,
                baslamaTarihi: new Date('2019-01-01'),
                hacizdenOnce: 5.60,
                hacizdenSonraSatistanOnce: 8.20,
                satistanSonra: 13.00,
                maasHaczindenSonra: 6.60,
                iflasKanunundan: 5.80,
                gayrimenkulIcraEmri: 3.90,
                gayrimenkulIcraMarifeti: 3.80,
                degisikMenkulIcraEmri: 3.80,
                degisikMenkulMarifeti: 5.60,
                icraYerineGetirmeHarci: 49.40,
                basvuruHarci: 49.40,
                vekaletHarci: 9.40,
                pesinHarcOrani: 2.50,
                cezaeviHarcOrani: 3.00,
                tebligatGideri: 17.00,
                dosyaGider: 12.75,
                evrakGideri: 1.50,
            },
            {
                id: 5,
                baslamaTarihi: new Date('2020-01-01'),
                hacizdenOnce: 6.60,
                hacizdenSonraSatistanOnce: 10.20,
                satistanSonra: 16.00,
                maasHaczindenSonra: 12.60,
                iflasKanunundan: 10.80,
                gayrimenkulIcraEmri: 10.30,
                gayrimenkulIcraMarifeti: 10.80,
                degisikMenkulIcraEmri: 10.80,
                degisikMenkulMarifeti: 12.60,
                icraYerineGetirmeHarci: 55.40,
                basvuruHarci: 50.40,
                vekaletHarci: 14.40,
                pesinHarcOrani: 10.50,
                cezaeviHarcOrani: 11.00,
                tebligatGideri: 23.00,
                dosyaGider: 10.75,
                evrakGideri: 10.10,
            },
            {
                id: 6,
                baslamaTarihi: new Date('2021-01-01'),
                hacizdenOnce: 23.60,
                hacizdenSonraSatistanOnce: 27.20,
                satistanSonra: 29.00,
                maasHaczindenSonra: 23.60,
                iflasKanunundan: 21.80,
                gayrimenkulIcraEmri: 20.90,
                gayrimenkulIcraMarifeti: 21.80,
                degisikMenkulIcraEmri: 21.80,
                degisikMenkulMarifeti: 23.60,
                icraYerineGetirmeHarci: 74.40,
                basvuruHarci: 74.40,
                vekaletHarci: 26.40,
                pesinHarcOrani: 20.50,
                cezaeviHarcOrani: 22.00,
                tebligatGideri: 33.00,
                dosyaGider: 21.75,
                evrakGideri: 20.50,
            },
        ]
    }
}