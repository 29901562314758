



































































































































































































import {Component, Mixins} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FaizPeriyotPicker from "@/components/pickers/FaizPeriyotPicker.vue";

@Component({
    components: {FaizPeriyotPicker}
})
export default class HarcGenelForm extends Mixins(ObjectInputMixin) {
}
