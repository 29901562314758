import {MasrafCesitEntity} from "@/entity/MasrafCesitEntity";


class MasrafCesitleriListResponse {
    data!: MasrafCesitEntity[]
}

export function MasrafCesitleriListResponseProvider(depth:number=3): MasrafCesitleriListResponse {
    return {
        data: [
            {
                id: 1,
                masrafAciklama: "Dosya Masrafi",
                tutar: 1.99,
                resmi: true,
                kapaktaGorunsun: true,
                makbuzdaGorunsun: true,
                takibeOtoEkle: false
            },
            {
                id: 2,
                masrafAciklama: "Evrak Masrafi",
                tutar: 1.00,
                resmi: false,
                kapaktaGorunsun: true,
                makbuzdaGorunsun: true,
                takibeOtoEkle: true
            },
            {
                id: 3,
                masrafAciklama: "Damga Pulu Masrafi",
                tutar: 4.99,
                resmi: false,
                kapaktaGorunsun: false,
                makbuzdaGorunsun: false,
                takibeOtoEkle: true
            },
            {
                id: 4,
                masrafAciklama: "Tebligat Gideri",
                tutar: 19.00,
                resmi: true,
                kapaktaGorunsun: true,
                makbuzdaGorunsun: true,
                takibeOtoEkle: true
            },


        ]
    }
}
