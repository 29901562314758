import {kartDurumlariEntity} from "@/entity/KartDurumlari";


class kartDurumlariListResponse {
    data!: kartDurumlariEntity[]
}

export function KartDurumlariListResponseProvider(depth:number=3): kartDurumlariListResponse {
    return {
        data: [
            {
                id: 1,
                durumAdi: "Derdest",
            },
            {
                id: 2,
                durumAdi: "İnfaz",
            },
            {
                id: 3,
                durumAdi: "Tahsil Kabiliyeti Yok",
            },
            {
                id: 4,
                durumAdi: "Temlik",
            },
            {
                id: 5,
                durumAdi: "Kısmi Feragat",
            },
            {
                id: 6,
                durumAdi: "Rehin Açığı Belgesi",
            },
            {
                id: 7,
                durumAdi: "Rehin Paraya Çevrildi.",
            },
            {
                id: 8,
                durumAdi: "Takipsiz",
            },
            {
                id: 9,
                durumAdi: "İMTZO",
            },
            {
                id: 10,
                durumAdi: "MTZO1",
            },
            {
                id: 11,
                durumAdi: "Aciz",
            },
            {
                id: 12,
                durumAdi: "Derkenar",
            },


        ]
    }
}
