












import {Component, Prop, Vue} from "vue-property-decorator";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import {VekaletUcretleriTaslakListProvider} from "@/services/VekaletUcretleriTaslaklarService";
import VekaletDonemList from "@/components/tercihler/VekaletDonemList.vue";


@Component({
    components: {VekaletDonemList, ItemArrayManager}
})
export default class VekaletUcretleriTaslakList extends  Vue{

    items = VekaletUcretleriTaslakListProvider().data;
    headers = [
        {
            text: "Tür Adı",
            value: "taslakAdi"
        },
        {
            text: "Oluşturan",
            value: "olusturan"
        },

    ];
}
