















































import {Component, Vue} from "vue-property-decorator";
import {MasrafCesitleriListResponseProvider} from "@/services/MasrafCesitleriService";
import MasrafCesitleriForm from "@/components/tercihler/MasrafCesitleriForm.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";

@Component({
    components: {FormDialogButton, MasrafCesitleriForm}
})
export default class MasrafCesitleri extends Vue {
    masrafCesitleri = MasrafCesitleriListResponseProvider().data;
    dialog: boolean = false;
    editingObject = Object.assign({});
    search: string = "";
    headers = [
        {
            text: "Masraf Çeşidi",
            align: "start",
            sortable: false,
            value: "masrafAciklama"
        },
        {
            text: "Tutar",
            align: "start",
            sortable: false,
            value: "tutar"
        },
        {text: "Takibe Ekle", value: "takibeOtoEkle"},
        {text: "Resmi", value: "resmiMi"},
        {text: "Kapakta Görünsün", value: "kapaktaGorunsun"},
        {text: "Makbuzda Görünsün", value: "makbuzdaGorunsun"},
        {text: "İşlemler", value: "actions", sortable: false}

    ];

    masrafCesitEkle() {
        this.editingObject = Object.assign({});
        this.dialog = true;
    }

    detay(val: any) {
        this.editingObject = val;
        this.dialog = true;
    }
}
