


















import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import OranForm from "@/components/tercihler/OranForm.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {ItemArrayManagerTableHeader, TableHeader} from "@/interface/TableHeader";

@Component({
    components: {OranForm, ItemArrayManager}
})
export default class OranList extends Mixins(ObjectInputMixin) {

    faizlerHeaders: Array<ItemArrayManagerTableHeader> = [
        {value: "baslangicTarihi", text: "Faiz Başlama Tarihi",renderer:(row)=> this.$helper.tarihStr(row.baslangicTarihi)},
        {value: "bitisTarihi",text: "Faiz Bitiş Tarihi",renderer:(row)=> this.$helper.tarihStr(row.bitisTarihi)},
        {value: "oran", text: "Oran"},
    ];

}
