
























































import {Component, Vue} from "vue-property-decorator";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import KartDurumuForm from "@/components/tercihler/KartDurumuForm.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import {TakipAltTipiResponseProvider} from "@/services/TakipAltTipiService";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import {TakipAltTipiEntity} from "@/entity/TakipAltTipiEntity";
import {TakipTipiListResponseProvider} from "@/services/TakipTipiService";
import {MuvekkilEntity} from "@/entity/MuvekkilEntity";


@Component({
    components: {ItemArrayManager, FormDialogButton, KartDurumuForm, DeleteButton}
})
export default class TakipAltTipler extends Vue {
    items = TakipAltTipiResponseProvider().data;
    takipAltTip = new TakipAltTipiEntity();
    takipTipleriState = TakipTipiListResponseProvider().data;
    takipAltTipleriState: Array<TakipAltTipiEntity> = [];
    headers = [
        {
            text: "Takip Tip",
            align: "start",
            sortable: false,
            value: "takipTipi.deger"
        },
        {
            text: "Takip Alt Tip",
            align: "start",
            sortable: false,
            value: "deger"
        },
        {text: "İşlemler", value: "actions" , width:150, sortable: false}
    ];

    mounted() {
        console.log("takip alt tipler", this.items)
        this.loadTakipAltTip();
    }
    loadTakipAltTip() {
        this.takipAltTipleriState = [];
        this.takipAltTipleriState = TakipAltTipiResponseProvider().data;
    }
    get takipAltTipleri(): Array<TakipAltTipiEntity> {
        if (!this.takipAltTip.takipTipi) {
            return [];
        }
        return this.takipAltTipleriState.filter(ttt => ttt.takipTipi?.id === this.takipAltTip?.takipTipi?.id);
    }
    detay(item: TakipAltTipiEntity) {
        this.$router.push('/takipAltTip/' + item.id!.toString());
    }
}
