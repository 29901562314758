var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto"},[_c('v-toolbar',{attrs:{"dense":"","color":"light-blue darken-4","dark":""}},[_c('v-toolbar-title',[_vm._v(" Masraf Çeşitleri ")]),_c('v-spacer'),_c('form-dialog-button',{attrs:{"title":"Masraf Çeşidi Bilgileri","action":"/api/masraf","dark":"","icon":"mdi-plus"},model:{value:(_vm.editingObject),callback:function ($$v) {_vm.editingObject=$$v},expression:"editingObject"}},[_c('h2',{staticClass:"black--text"},[_vm._v("Masraf Çeşidi")]),_c('masraf-cesitleri-form',{model:{value:(_vm.editingObject),callback:function ($$v) {_vm.editingObject=$$v},expression:"editingObject"}})],1)],1),_c('v-data-table',{staticClass:"table table-striped",attrs:{"dense":"","headers":_vm.headers,"search":_vm.search,"items":_vm.masrafCesitleri,"no-data-text":"Kayıtlı Masraf bulunamadı. Eklemek için + butonunu kullan! ","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.takibeOtoEkle",fn:function(ref){
var item = ref.item;
return [(item.takibeOtoEkle === true)?_c('v-icon',[_vm._v("mdi-check-box-outline")]):_c('v-icon',[_vm._v("mdi-crop-square")])]}},{key:"item.resmiMi",fn:function(ref){
var item = ref.item;
return [(item.resmi === true)?_c('v-icon',[_vm._v("mdi-check-box-outline")]):_c('v-icon',[_vm._v("mdi-crop-square")])]}},{key:"item.kapaktaGorunsun",fn:function(ref){
var item = ref.item;
return [(item.kapaktaGorunsun === true)?_c('v-icon',[_vm._v("mdi-check-box-outline")]):_c('v-icon',[_vm._v("mdi-crop-square")])]}},{key:"item.makbuzdaGorunsun",fn:function(ref){
var item = ref.item;
return [(item.makbuzdaGorunsun === true)?_c('v-icon',[_vm._v("mdi-check-box-outline")]):_c('v-icon',[_vm._v("mdi-crop-square")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('form-dialog-button',{attrs:{"title":"Masraf Çeşidi Bilgileri","medium":"","action":"/api/masraf","icon":"mdi-pencil","color":"green"},model:{value:(item),callback:function ($$v) {item=$$v},expression:"item"}},[_c('masraf-cesitleri-form',{model:{value:(item),callback:function ($$v) {item=$$v},expression:"item"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }