



























import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import IptalButton from "@/components/inputs/IptalButton.vue";
import KaydetButton from "@/components/inputs/KaydetButton.vue";
import Dates from "@/components/inputs/Dates.vue";
import {FaizOranGecmisEntity} from "@/entity/FaizOranGecmisEntity";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
    components: {Dates, KaydetButton, IptalButton}
})
export default class OranForm extends Mixins(ObjectInputMixin) {

    @Prop({})
    standartMi!: boolean;


}
