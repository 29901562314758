






















import {Component, Vue} from "vue-property-decorator";
import MasrafCesitleriList from "@/components/tercihler/MasrafCesitleriList.vue";
import KartDurumuList from "@/components/tercihler/KartDurumuList.vue";
import FaizlerList from "@/components/tercihler/FaizlerList.vue";
import GenelAyarlar from "@/components/tercihler/GenelAyarlar.vue";
import TakipAltTipler from "@/components/tercihler/TakipAltTipler.vue";
import VekaletUcretleriTaslakList from "@/components/tercihler/VekaletUcretleriTaslakList.vue";
import Harclar from "@/components/tercihler/Harclar.vue";


@Component({
    components: {
        Harclar,
        VekaletUcretleriTaslakList,
        TakipAltTipler,
        GenelAyarlar,
        FaizlerList,
        KartDurumuList, MasrafCesitleriList
    },
})
export default class Tercihler extends Vue {

}

